<template>
    <CRow>
        <CCol col="12" xxl="6">
            <CCard>
                <CCardHeader>
                    <strong class="text-value-lg mr-2">Add Twitteraccounts</strong>
                </CCardHeader>
                <CCardBody>
                    <CForm @submit.prevent="submit">
                        <label>Username(s)</label>
                        <CTextarea placeholder="By username (or comma separated list) without '@'" rows="2" v-model="userNames"/>
                        <label>Tags (applied to all new Accounts)</label>
                        <TagSelect v-model="selectedTags"/>
                        <CButton type="submit" color="primary" class="mt-3">
                            Submit
                        </CButton>
                    </CForm>
                </CCardBody>
                <CElementCover v-if="loading"/>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {format} from 'date-fns'
    import {de} from 'date-fns/locale'

    import TagSelect from "./modules/TagSelect";

    export default {
        name: "AddUser",
        components: {
            TagSelect
        },
        data() {
            return {
                userNames: '',
                selectedTags: [],
                loading: false
            }
        },
        methods: {
            ...mapActions([
                'addTwitterUser',
                'addToast',
            ]),
            ...mapMutations([
                'setCurrentUsersNeedReloading',
            ]),
            submit() {
                this.loading = true;
                console.log("try add:", this.userNames, this.selectedTags);
                this.addTwitterUser({"usernames": this.userNames, "tags": this.selectedTags.join()}).then((res) => {
                    console.log("added Users:", res);
                    if (res.data.not_found) {
                        res.data.not_found.forEach(element => {
                            this.addToast({text: 'Did not find "' + element + '"', color: 'danger'})
                        });
                    }
                    if (res.data.error) {
                        res.data.error.forEach(element => {
                            this.addToast({text: 'An error occurred while trying to add "' + element + '"', color: 'danger'})
                        });
                    }
                    if (res.data.already_in_db) {
                        res.data.already_in_db.forEach(element => {
                            this.addToast({text: 'Account "' + element + '" is already in DB', color: 'warning'})
                        });
                    }
                    if (res.data.found) {
                        res.data.found.forEach(element => {
                            this.addToast({text: 'Added "' + element + '"', color: 'success'})
                        });
                    }
                    this.setCurrentUsersNeedReloading(true);
                    this.loading = false;
                });
                this.setCurrentUsersNeedReloading(true);
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentTwitterUsers',
                'getCurrentTwitterTags'
            ]),
        }
    }
</script>

<style scoped>

</style>
